import React from 'react';
import axios from 'redaxios';
import ReactGA from 'react-ga';
import * as Sentry from '@sentry/browser';
import { navigate } from 'gatsby';
import { Box } from '@chakra-ui/react';

import { SubmitButton } from 'timelesstime-ui/components/capture-details-form/capture-details-form';
import emberRouteToUrl from 'timelesstime-ui/utils/ember-route-to-url';
import TrackingContext from 'timelesstime-ui/contexts/tracking';
import CaptureDetailsForm from 'timelesstime-ui/components/capture-details-form';

const GACategory = 'tool-registration';

const toolRegistrationFields = [
  {
    name: 'firstName',
    label: 'First Name',
    required: true,
    placeholder: 'Joe/Jane',
    validate: (value) =>
      (typeof value !== 'string' || value.length < 3) &&
      'First name must be provided',
  },
  {
    name: 'lastName',
    label: 'Last Name',
    required: true,
    placeholder: 'Bloggs',
    validate: (value) =>
      (typeof value !== 'string' || value.length < 3) &&
      'Last name must be provided',
  },
  {
    name: 'email',
    label: 'Email',
    required: true,
    placeholder: 'me@bloggs.com',
    validate: (value) =>
      (typeof value !== 'string' || value.length < 3) &&
      'Email must be provided',
  },
  {
    name: 'company',
    label: 'Company',
    required: false,
    placeholder: 'Bloggs Ltd.',
  },
  {
    name: 'jobTitle',
    label: 'Job Title',
    required: false,
    placeholder: 'Manager',
  },
];

const handleSubmit = async (formData) => {
  try {
    await axios.post('/tool-registration', {
      data: {
        email: formData.email,
        firstName: formData.firstName,
        lastName: formData.lastName,
        title: formData.title,
        company: formData.company,
        trigger: 'tool registration',
      },
    });
    ReactGA.event({
      category: GACategory,
      action: 'submitted',
    });
    return 'Thank you, your details have been submitted';
  } catch (error) {
    Sentry.captureException(error);
    throw new Error(
      'There was an error signing you up. Please try again later or email info@timelesstime.co.uk',
    );
  }
};

const LandingToolRegistrationForm = ({
  successRouteName,
  successRouteArg,
  buttonText,
  ...props
}) => (
  <Box
    bg="gray.50"
    border="1px solid"
    borderColor="gray.100"
    borderRadius="sm"
    px={4}
    py={4}
    pb={4}
    {...props}
  >
    <TrackingContext.Consumer>
      {(tracking) => (
        <CaptureDetailsForm
          fields={toolRegistrationFields}
          renderSubmitButton={(submitProps) => (
            <SubmitButton text={buttonText} {...submitProps} />
          )}
          handleSubmit={async (formData) => {
            tracking.associate(
              formData.email,
              formData.firstName,
              formData.lastName,
            );
            const submitText = await handleSubmit(formData);
            if (
              typeof successRouteName === 'string' &&
              successRouteName.length > 0
            ) {
              const naviateToUrl = emberRouteToUrl(
                successRouteName,
                successRouteArg,
              );
              navigate(naviateToUrl);
            }
            return submitText;
          }}
          {...props}
        />
      )}
    </TrackingContext.Consumer>
  </Box>
);

export default LandingToolRegistrationForm;
