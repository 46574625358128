import React from 'react';
import axios from 'redaxios';
import ReactGA from 'react-ga';
import * as Sentry from '@sentry/browser';
import download from 'downloadjs';
import {
  Box,
  Container,
  FormLabel,
  FormControl,
  Input,
  FormHelperText,
  VStack,
  Text,
  Flex,
  HStack,
} from '@chakra-ui/react';

import CanonicalLink from 'timelesstime-ui/components/canonical-link';
import CaptureDetailsForm from 'timelesstime-ui/components/capture-details-form';
import TrackingContext from 'timelesstime-ui/contexts/tracking';
import { SubmitButton } from 'timelesstime-ui/components/capture-details-form/capture-details-form';

const GACategory = 'file-download-email-form';

const fileDownloadFormFields = [
  {
    name: 'email',
    label: 'Email',
    required: true,
    placeholder: '',
    validate: (value) =>
      (typeof value !== 'string' || value.length < 3) &&
      'Email must be provided',
    createFormInput: ({
      labelText,
      type,
      onChange,
      onBlur,
      name,
      required,
      placeholder,
      validationErrors,
      isInvalid,
    }) => (
      <FormControl isRequired={required} isInvalid={isInvalid} mb={2}>
        <Flex>
          <FormLabel textAlign="right" mt={2} mb={0}>
            {labelText}
          </FormLabel>
          <Flex flexDir="column" flexGrow="1">
            <Input
              type={type}
              onChange={onChange}
              onBlur={onBlur}
              name={name}
              required={required}
              placeholder={placeholder}
            />
            {isInvalid && (
              <FormHelperText mb={2}>
                <VStack align="flex-start">
                  {validationErrors.map((error) => (
                    <Text color="red" key={error}>
                      {error}
                    </Text>
                  ))}
                </VStack>
              </FormHelperText>
            )}
          </Flex>
        </Flex>
      </FormControl>
    ),
  },
];

const handleSubmit = async (formData, successDownloadFile) => {
  const {
    file: {
      url: downloadFileUrl,
      fileName: downloadFileName,
      contentType: downloadFileContentType,
    },
  } = successDownloadFile;
  try {
    await axios.post('/tool-registration', {
      data: {
        email: formData.email,
        trigger: 'landing file download email',
      },
    });
    ReactGA.event({
      category: GACategory,
      action: 'submitted',
    });
    setTimeout(() => {
      download(downloadFileUrl, downloadFileName, downloadFileContentType);
    }, 0);
    return (
      <Box>
        <Text my={2}>Thanks. Your email should start soon.</Text>
        <Text my={2}>
          If you have any problems donwload the file email us,
          info@timelesstime.co.uk
        </Text>
        <HStack spacing={2}>
          <CanonicalLink color="orange.500" to="/knowledgebase/">
            Browse our knowledgebase
          </CanonicalLink>
          <CanonicalLink color="orange.500" to="/">
            Go Home
          </CanonicalLink>
        </HStack>
      </Box>
    );
  } catch (error) {
    Sentry.captureException(error);
    throw new Error(
      'There was an error. Please try again later or email info@timelesstime.co.uk',
    );
  }
};

const FileDownloadEmailForm = ({
  successDownloadFile,
  buttonText,
  ...props
}) => (
  <Container as={Flex} {...props} alignContent="center" flexDir="column">
    <Box
      px={4}
      py={4}
      bg="gray.50"
      border="1px solid"
      borderColor="gray.100"
      borderRadius="sm"
    >
      <Text as="strong" mb={4} display="block">
        Submit your email for immediate access
      </Text>
      <TrackingContext.Consumer>
        {(tracking) => (
          <CaptureDetailsForm
            fields={fileDownloadFormFields}
            handleSubmit={async (formData) => {
              tracking.associate(
                formData.email,
                formData.firstName,
                formData.lastName,
              );
              return handleSubmit(formData, successDownloadFile);
            }}
            renderSubmitButton={(submitProps) => (
              <SubmitButton text={buttonText} {...submitProps} />
            )}
            showPersonalEmailWarning={false}
          />
        )}
      </TrackingContext.Consumer>
    </Box>
  </Container>
);

export default FileDownloadEmailForm;
