import React from 'react';
import axios from 'redaxios';
import ReactGA from 'react-ga';
import * as Sentry from '@sentry/browser';
import { Box, Text, HStack } from '@chakra-ui/react';

import ButtonLink from 'timelesstime-ui/components/button-link';
import BaseContactForm from 'timelesstime-ui/components/contact-form';
import TrackingContext from 'timelesstime-ui/components/../contexts/tracking';
import { SubmitButton } from 'timelesstime-ui/components/capture-details-form/capture-details-form';

const GACategory = 'landing-contact-form';

const handleSubmit = async (formData) => {
  try {
    await axios.post('/contact', {
      data: {
        email: formData.email,
        phoneNumber: formData.phoneNumber,
        firstName: formData.firstName,
        lastName: formData.lastName,
        title: formData.title,
        company: formData.company,
        jobTitle: formData.jobTitle,
        message: formData.message,
        trigger: 'landing contact form',
      },
    });
    ReactGA.event({
      category: GACategory,
      action: 'submitted',
    });
    return (
      <Box>
        <Text>Thank you, your message has been sent.</Text>
        <HStack spacing={2}>
          <ButtonLink colorScheme="orange" to="/knowledgebase/">
            Browse our knowledgebase
          </ButtonLink>
          <ButtonLink colorScheme="orange" to="/">
            Go Home
          </ButtonLink>
        </HStack>
      </Box>
    );
  } catch (error) {
    Sentry.captureException(error);
    throw new Error(
      'There was an error sending your message. Please try again later or email info@timelesstime.co.uk',
    );
  }
};

const ContactForm = ({ buttonText, ...props }) => (
  <Box {...props}>
    <TrackingContext.Consumer>
      {(tracking) => (
        <BaseContactForm
          handleSubmit={(formData) => {
            tracking.associate(
              formData.email,
              formData.firstName,
              formData.lastName,
            );
            return handleSubmit(formData);
          }}
          renderSubmitButton={(submitProps) => (
            <SubmitButton text={buttonText} {...submitProps} />
          )}
        />
      )}
    </TrackingContext.Consumer>
  </Box>
);

export default ContactForm;
