export default (routeName, routeArg) => {
  const routeNameParts = routeName.split('.');
  if (
    routeNameParts.length === 1 ||
    typeof routeArg !== 'string' ||
    routeArg.length === 0
  ) {
    return routeNameParts[0];
  }
  return `${routeNameParts[0]}/${routeArg}`;
};
