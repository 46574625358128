import React, { useRef } from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { FaAngleDoubleLeft } from 'react-icons/fa';
import {
  Box,
  Container,
  Flex,
  VStack,
  SimpleGrid,
  Button,
} from '@chakra-ui/react';

import CanonicalLink from 'timelesstime-ui/components/canonical-link';
import SEO from 'timelesstime-ui/components/seo';
import Html from 'timelesstime-ui/components/html';
import BlockQuote from 'timelesstime-ui/components/block-quote';
import Heading from 'timelesstime-ui/components/heading';
import Jumbotron from 'timelesstime-ui/components/jumbotron';
import ButtonLink from 'timelesstime-ui/components/button-link';
import emberRouteToUrl from 'timelesstime-ui/utils/ember-route-to-url';
import PageLayout from '../components/layout';
import PeopleJumbotron from '../components/people-jumbotron';
import landingComponentFactory from '../components/landing';

const CallToActionButton = ({
  landingPageComponentRef,
  contentfulLandingPage,
}) => {
  if (contentfulLandingPage.landingComponentSuccessRouteName) {
    return (
      <ButtonLink
        colorScheme="orange"
        to={emberRouteToUrl(
          contentfulLandingPage.landingComponentSuccessRouteName,
          contentfulLandingPage.landingComponentSuccessRouteArg,
        )}
      >
        {contentfulLandingPage.jumbotronCallToActionText}
      </ButtonLink>
    );
  }
  return (
    <Button
      mt={6}
      colorScheme="orange"
      onClick={() => {
        const scrollToElement = landingPageComponentRef.current;
        const scrollToElementY = scrollToElement.offsetTop;
        if ('scrollBehavior' in document.documentElement.style) {
          window.scrollTo({
            behavior: 'smooth',
            left: 0,
            top: scrollToElementY,
          });
        } else {
          window.scrollTo(0, scrollToElementY);
        }
      }}
    >
      {contentfulLandingPage.jumbotronCallToActionText}
    </Button>
  );
};

const LandingPage = ({
  data: { contentfulLandingPage, timelesstimeHeadLogo },
}) => {
  const landingPageComponentRef = useRef();
  return (
    <PageLayout navbar={null}>
      <SEO
        title={contentfulLandingPage.title}
        keywords={contentfulLandingPage.fields.keywords}
        description={contentfulLandingPage.metaDescription}
        canonicalPath={contentfulLandingPage.fields.path}
      />
      <Container maxW="container.lg" mt={4}>
        <Box position="absolute">
          <CanonicalLink color="orange.500" to="/">
            <FaAngleDoubleLeft
              style={{ marginTop: '-2px', marginRight: '2px' }}
            />
            home
          </CanonicalLink>
        </Box>
        <Flex justify="center" mb={8}>
          <VStack spacing={2}>
            <CanonicalLink to="/" aria-label="TimelessTime Home">
              <GatsbyImage
                image={getImage(timelesstimeHeadLogo)}
                alt={timelesstimeHeadLogo.name}
              />
            </CanonicalLink>
            <CanonicalLink _hover={{ textDecration: 'none' }} to="/">
              <Heading as="h1" fontSize="lg">
                TimelessTime
              </Heading>
            </CanonicalLink>
            <Heading as="h2" fontSize="md">
              Because your people matter
            </Heading>
          </VStack>
        </Flex>
      </Container>

      <Container maxW="container.lg" mt={4}>
        <Heading as="h1">{contentfulLandingPage.topLevelHeading}</Heading>
        <Heading as="h2">{contentfulLandingPage.secondLevelHeading}</Heading>
        <SimpleGrid as="section" mt={8} mb={6} spacing={6} columns={[1, 1, 2]}>
          <Box>
            <Html
              mb={6}
              source={contentfulLandingPage.fields.landingContentHtml}
              headerLevelStart={2}
            />
            <Box ref={landingPageComponentRef} mb={8}>
              {contentfulLandingPage.landingComponent &&
                landingComponentFactory(
                  contentfulLandingPage.landingComponent,
                  {
                    successRouteName:
                      contentfulLandingPage.landingComponentSuccessRouteName,
                    successRouteArg:
                      contentfulLandingPage.landingComponentSuccessRouteArg,
                    successDownloadFile:
                      contentfulLandingPage.landingComponentSuccessDownloadFile,
                    buttonText:
                      contentfulLandingPage.landingComponentButtonText,
                  },
                )}
            </Box>
          </Box>
          <Box display={['none', 'none', 'block']}>
            <GatsbyImage
              image={getImage(contentfulLandingPage.leadInImage)}
              alt={contentfulLandingPage.leadInImage.title}
            />
          </Box>
        </SimpleGrid>
      </Container>
      <Container as="section" maxW="container.lg" mt={4} mb={8}>
        <Flex justifyContent="center">
          <BlockQuote
            text={contentfulLandingPage?.quotes?.[0]?.text || ''}
            person={contentfulLandingPage?.quotes?.[0]?.person || ''}
            position={contentfulLandingPage?.quotes?.[0]?.position || ''}
            company={contentfulLandingPage?.quotes?.[0]?.company || ''}
          />
          {contentfulLandingPage?.quotes?.[0]?.caseStudies?.[0]?.fields
            ?.path && (
            <CanonicalLink
              mt={6}
              color="orange.500"
              to={
                contentfulLandingPage?.quotes?.[0]?.caseStudies?.[0]?.fields
                  ?.path
              }
            >
              Read the full case study
            </CanonicalLink>
          )}
        </Flex>
      </Container>

      <PeopleJumbotron
        height={['200px', '250px', '300px']}
        _before={{
          backgroundPositionY: [
            'center !important', // base
            'center !important', // sm
            'center !important', // md
            '-50px !important', // lg
            '-100px !important',
          ],
        }}
        _after={{
          backgroundPositionY: [
            'center !important', // base
            'center !important', // sm
            'center !important', // md
            '-50px !important', // lg
            '-100px !important',
          ],
        }}
      />

      <Jumbotron as="section" py={8}>
        <Container maxW="container.lg" mt={4}>
          <Heading as="h1" color="white">
            {contentfulLandingPage.jumbotronHeading}
          </Heading>
          <Html
            source={contentfulLandingPage.fields.jumbotronContentHtml}
            headerLevelStart={1}
          />
          <CallToActionButton
            landingPageComponentRef={landingPageComponentRef}
            contentfulLandingPage={contentfulLandingPage}
          />
        </Container>
      </Jumbotron>
    </PageLayout>
  );
};

export const query = graphql`
  query LandingPageBySlug($slug: String!) {
    timelesstimeHeadLogo: file(name: { eq: "TimelessTime-Logo-Square" }) {
      name
      childImageSharp {
        gatsbyImageData(width: 100, placeholder: NONE, layout: FIXED)
      }
    }
    contentfulLandingPage(slug: { eq: $slug }) {
      title
      slug
      metaDescription
      topLevelHeading
      secondLevelHeading
      landingComponent
      landingComponentButtonText
      landingComponentSuccessRouteName
      landingComponentSuccessRouteArg
      landingComponentSuccessDownloadFile {
        file {
          url
        }
      }
      quotes {
        id
        person
        position
        company
        # get raw text here since we need it for structured data
        text {
          internal {
            content
          }
        }
        caseStudies {
          id
          title
          slug
        }
        fields {
          textHtml
        }
      }
      leadInImage {
        title
        gatsbyImageData(width: 500, placeholder: BLURRED, layout: CONSTRAINED)
      }
      jumbotronHeading
      jumbotronCallToActionText
      fields {
        path
        url
        writtenAt
        isNew
        keywords
        landingContentHtml
        jumbotronContentHtml
      }
    }
  }
`;

export default LandingPage;
