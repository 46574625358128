import React from 'react';
import ContactForm from './contact-form';
import FileDownloadEmailForm from './file-download-email-form';
import ToolRegistrationForm from './tool-registration-form';

export default (landingName, props = {}) => {
  switch (landingName) {
    case 'contact-form':
      return <ContactForm {...props} />;
    case 'file-download-email-form':
      return <FileDownloadEmailForm {...props} />;
    case 'tool-registration-form':
      return <ToolRegistrationForm {...props} />;
    default:
      return null;
  }
};
